import React from "react"
import {
  Section,
  Wrapper,
  Header,
  Heading,
  Subheading,
} from "./styles/ComparisonTable.styled"
import ColumnsHeaders from "./components/ColumnsHeaders"
import Table from "./components/Table"
import MobileTables from "./components/MobileTables"
import { defaultResolveRichText } from "../../utils/resolve-rich-text/defaultResolveRichText"

const ComparisonTables = ({ component }) => (
  <Section noTopPadding={component?.noTopPadding} noBottomPadding={component?.noBottomPadding}>
    <Wrapper>
      <Header>
        <Heading>{component?.heading}</Heading>
        {component.subheading && (
          <Subheading>
            {defaultResolveRichText(component?.subheading)}
          </Subheading>
        )}
      </Header>
      {component?.columnHeaders &&
        <ColumnsHeaders component={component.columnHeaders} />
      }
      {component?.tables.map(table => (
        <Table key={table?.id} component={table} />
      ))}
      <MobileTables component={component} />
    </Wrapper>
  </Section>
)

export default ComparisonTables
