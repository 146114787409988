export const setMobileTableData = tables => {
  const mobileTables = []

  tables.map(table => {
    let grouped = []
    let zeroIndex
    let title = table.tableTitle
    table.tableColumns.map((col, idxInner) => {
      if (idxInner === 0) {
        zeroIndex = col
      } else {
        grouped.push({
          title: title,
          cols: [zeroIndex, col],
        })
      }
    })
    mobileTables.push(grouped)
  })
  return mobileTables
}

export const orderMobileTables = data => {
  const groups = setMobileTableData(data)
  const orderedTables = []

  groups.map(group => {
    group.map((table, idx) => {
      if (orderedTables[idx]) {
        orderedTables[idx].push(table)
      } else {
        orderedTables.push([table])
      }
    })
  })
  return orderedTables
}
