import styled from "styled-components"

export const TablesWrapper = styled.div`
  display: ${props => (props.showTables ? "flex" : "none")};
  flex-direction: column;
  gap: 32px;
`
export const Header = styled.div`
  padding: 16px;
  border-radius: 8px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  color: #ffffff;
  background: #090069;
`
export const Cols = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
`
export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  cursor: pointer;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #002dc2;
  img {
    margin-left: 14px;
    transform: ${props => (props?.showTables ? "unset" : "rotate(180deg)")};
  }
`
