import styled from "styled-components"

export const TableWrapper = styled.div`
  border: 1px solid #d0d5dd;
  box-shadow: 0px 20px 30px rgba(39, 63, 251, 0.08);
  border-radius: 16px;
  overflow: hidden;
  margin: 32px 0px;
  @media (max-width: 992px) {
    display: none;
  }
`

export const Header = styled.div`
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  background: #090069;
  color: #ffffff;
`

export const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  background: #090069;
  color: #ffffff;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
`
export const Button = styled.button`
  background: transparent;
  border: none;
  height: 100%;
  width: 44px;
  cursor: pointer;
  img {
    transform: ${props =>
      props?.tableOpen ? "rotate(180deg)" : "rotate(0deg)"};
  }
`

export const Dropdown = styled.div`
  display: ${props => (props?.tableOpen ? "grid" : "none")};
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
`

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid #d0d5dd;
  &:last-child {
    border-right: unset;
  }
`
