import styled from "styled-components"

export const Col = styled.div`
  display: grid;
  grid-auto-rows: 1fr;
`

export const Item = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  font-family: "Roboto";
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  font-weight: ${props => (props.colId > 0 ? 700 : 400)};
  color: ${props => (props.colId > 0 ? "#1B1B1B" : "#475467")};
  justify-content: ${props => (props.colId > 0 ? "center" : "left")};
  padding: 16px;
  background: #ffffff;
  &:nth-child(even) {
    background: #f9fafb;
  }
`
