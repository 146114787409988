import styled, { css } from "styled-components"

export const Section = styled.section`
  padding: 96px 0px;
  @media (max-width: 992px) {
    padding: 64px 0px;
  }
  @media (max-width: 576px) {
    padding: 48px 0px;
  }
  ${({ noTopPadding, noBottomPadding }) => css`
    padding-top: ${noTopPadding === true && 0};
    padding-bottom: ${noBottomPadding === true && 0};
    @media (max-width: 992px) {
      padding-top: ${noTopPadding === true && 0};
      padding-bottom: ${noBottomPadding === true && 0};
    }
    @media (max-width: 576px) {
      padding-top: ${noTopPadding === true && 0};
      padding-bottom: ${noBottomPadding === true && 0};
    }
  `}
`

export const Wrapper = styled.div`
  max-width: 1230px;
  padding: 0px 30px;
  margin: 0 auto;
  @media (max-width: 992px) {
    max-width: 830px;
  }
  @media (max-width: 576px) {
    max-width: 566px;
  }
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin-bottom: 48px;
`

export const Heading = styled.h2`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 50px;
  line-height: 60px;
  color: #101828;
  text-align: center;
  @media (max-width: 576px) {
    font-weight: 700;
    font-size: 38px;
    line-height: 48px;
  }
`
export const Subheading = styled.div`
  text-align: center;
  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 30px;
    text-align: center;
    color: #475467;
  }
`
