import styled from "styled-components"

export const Wrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  @media (max-width: 992px) {
    display: none;
  }
`
export const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  max-width: 230px;
  margin: 0 auto;
`
export const Icon = styled.div`
  width: 64px;
  height: 64px;
`

export const Heading = styled.p`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  color: #1b1b1b;
  text-align: center;
`
export const Subhead = styled.p`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #475467;
  text-align: center;
`
