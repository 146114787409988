import styled from "styled-components"

export const Item = styled.div`
  padding: 16px 24px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-weight: ${props => (props.idx > 0 ? 700 : 400)};
  color: ${props => (props.idx > 0 ? "#1B1B1B" : "#475467")};
  text-align: ${props => (props.idx > 0 ? "center" : "left")};
  background: #ffffff;
  &:nth-child(even) {
    background: #f9fafb;
  }
`
