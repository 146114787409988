import React from "react"
import { Col, Item } from "../styles/MobileColumn.styled"
import Check from "../assets/check.svg"
import Dash from "../assets/dash.svg"

const MobileColumn = ({ component, colId }) => (
  <Col>
    {component.tableItems.map(item => (
      <Item colId={colId}>
        {item?.showCheck && <img src={Check} alt="check" />}
        {item?.content && item?.content !== "" && <p>{item?.content}</p>}
        {item?.showEmpty && <img src={Dash} alt="empty" />}
      </Item>
    ))}
  </Col>
)

export default MobileColumn
