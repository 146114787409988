import styled from "styled-components"

export const Wrapper = styled.div`
  display: none;
  @media (max-width: 992px) {
    display: flex;
    flex-direction: column;
    gap: 48px;
  }
`

export const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 24px;
  border: 1px solid #d0d5dd;
  border-radius: 16px;
  flex-direction: column;
`
