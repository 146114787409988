import React from "react"
import { orderMobileTables } from "../utils/setMobileData"
import { Wrapper, Group } from "../styles/MobileTables.styled"
import MobileColumnHeader from "./MobileColumnHeader"
import MobileDropdown from "./MobileDropdown"

const MobileTables = ({ component }) => {
  const orderedTables = orderMobileTables(component?.tables)
  return (
    <Wrapper>
      {orderedTables.map((group, idx) => (
        <Group>
          {component?.columnHeaders?.map((col, innerIdx) => {
            if (idx === innerIdx) {
              return <MobileColumnHeader component={col} />
            }
          })}
          <MobileDropdown component={group} />
        </Group>
      ))}
    </Wrapper>
  )
}

export default MobileTables
