import React, { useState } from "react"
import {
  TableWrapper,
  HeaderRow,
  Button,
  Dropdown,
  Col,
} from "../styles/Table.styled"
import TableItem from "./TableItem"
import DownArrow from "../assets/down-arrow.svg"

const Table = ({ component }) => {
  const [tableOpen, setTableOpen] = useState(true)

  return (
    <TableWrapper>
      <HeaderRow>
        <div>{component?.tableTitle}</div>
        <Button onClick={() => setTableOpen(!tableOpen)} tableOpen={tableOpen}>
          <img src={DownArrow} alt="icon" />
        </Button>
      </HeaderRow>

      <Dropdown tableOpen={tableOpen}>
        {component?.tableColumns.map((col, idx) => (
          <Col>
            {col?.tableItems.map(item => (
              <TableItem idx={idx} item={item} />
            ))}
          </Col>
        ))}
      </Dropdown>
    </TableWrapper>
  )
}

export default Table
